.custom-scrollbar::-webkit-scrollbar {
    width: 8px;
   height: 1px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-track {
    background: transparent; 
  }
   
  .custom-scrollbar::-webkit-scrollbar-thumb {
    background: #5C5C5C; 
    border-radius: 12px;
    height: 1px;
  }
  
  .custom-scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  @media (max-width: 500px) {
    .custom-scrollbar::-webkit-scrollbar-thumb {
      background: transparent;
    }

    .custom-scrollbar::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }

   
  }